<template>
  <header id="header">
    <b-container class="px-md-0">
      <b-row
        align-v="center"
        class="justify-content-md-between"
      >
        <b-col
          class="text-center text-md-left"
          md="5"
        >
          <h1 class="d-inline-block">
            <router-link :to="`/${$t('lang')}/`">
              <img
                alt=""
                :src="`${$t('baseurl')}img/icons/carte.svg`"
              />
              <span v-html="$t('color.carte')"></span>
            </router-link>
          </h1>
        </b-col>
        <b-col
          class="d-flex justify-content-center justify-content-md-end"
          md="7"
        >
          <b-nav
            class="list-unstyled justify-content-center"
            pills
          >
            <b-nav-text>
              <a
                class="btn btn-primary text-uppercase mx-1"
                :href="`${$t('link.carte')}/${$t('lang')}/login/`"
                v-html="$t('hos.header.signin')"
              ></a>
            </b-nav-text>
            <b-nav-text
              class="nav-item"
              tag="li"
            >
              <I18n />
            </b-nav-text>
          </b-nav>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';

export default {
  components: {
    I18n,
  },
};
</script>

<style lang="scss">
#header {
  background: #fff;
  border-bottom: 1px solid var(--f-g4);

  h1 {
    margin: 0;
    line-height: normal;
    font-size: 2rem;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
        padding: .25rem .25rem .25rem 0;
      }
    }
  }

  .btn {
    border: transparent;
  }
}
</style>
